// 英 Raleway
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,500;0,700;0,900;1,400&display=swap");
// 中 Noto Sans TC
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");
// Roboto Slab
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;500;700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Raleway", Gotham Rounded, sans-serif;
  font-weight: normal;
}

html,
body {
  // height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
div,
p,
label,
span {
  font-family: "Raleway", "Noto Sans TC", sans-serif, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.Roboto-Slab {
  font-family: "Roboto Slab";
}

@layer components {
  body {
    @apply bg-light-blue bg-cover bg-fixed bg-center bg-no-repeat text-black;
    @apply before:absolute before:top-0 before:right-0;
    @apply after:absolute after:left-0 after:bottom-16 after:-z-20 after:h-0 after:w-0;
    &.sign {
      @apply flatMin:bg-sign-cover;
      @apply flat:before:content-sign-mobile-cover-right flat:after:content-sign-mobile-cover-left;
    }
    &.file {
      @apply flatMin:bg-file-cover;
      @apply flat:before:content-file-mobile-cover-right flat:after:content-file-mobile-cover-left;
    }
  }

  main {
    @apply flex h-[calc(100vh-4rem)] items-center justify-center gap-24 px-6;
    foo: bar;
    @apply flat:flex-col flat:items-start flat:justify-start flat:gap-4 flat:pt-9;

    > div:last-child:not(.not-w) {
      @apply w-[588px] flat:w-full flat:max-w-full flat:flex-initial;
      foo: bar;
    }
  }

  .h1 {
    @apply text-6xl font-medium flat:text-[40px];
    foo: bar;
  }
  .h4 {
    @apply text-2xl flat:text-base;
  }

  .bg-checkerboard {
    @apply flex items-center justify-center rounded-md bg-[#d9d9d9];
    foo: bar;
    background-image: linear-gradient(45deg, transparent 75%, #eee 0),
      linear-gradient(45deg, #eee 25%, transparent 0),
      linear-gradient(45deg, transparent 75%, #eee 0),
      linear-gradient(45deg, #eee 25%, transparent 0);
    background-size: 30px 30px;
    background-position: 0 0, 15px 15px, 15px 15px, 0 0;
  }
}
.animation-forwards {
  animation-fill-mode: forwards;
}
