.scale-up-top-right {
  animation: scale-up-top-right 0.4s;
}
@keyframes scale-up-top-right {
  0% {
    transform: scale(0.5);
    transform-origin: top right;
  }
  100% {
    transform: scale(1);
    transform-origin: top right;
  }
}

.scale-down-top-right {
  animation: scale-down-top-right 0.4s;
}

@keyframes scale-down-top-right {
  0% {
    transform: scale(1);
    transform-origin: right top;
  }
  100% {
    transform: scale(0.4);
    transform-origin: right top;
  }
}

.slide-top {
  animation: slide-top 0.2s linear both;
}
@keyframes slide-top {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-bottom {
  animation: slide-bottom 0.4s linear both;
}
@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}
