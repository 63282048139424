@layer components {
  .btn-primary {
    @apply rounded-large bg-blue p-3 text-white shadow-primary;
    @apply focus:active:bg-violet-blue disabled:cursor-not-allowed disabled:opacity-30;
    &:hover,
    &:focus,
    &:active {
      @apply shadow-none;
    }
  }

  .btn-secodary {
    @apply rounded-large bg-white p-3 text-black shadow-secodary;
    @apply active:focus:bg-[#FCFEFF] disabled:cursor-not-allowed disabled:opacity-30;
    foo: bar;
    &:hover,
    &:focus,
    &:active {
      @apply shadow-none;
    }
  }

  .btn-alter {
    @apply rounded-large bg-alert-red p-3 text-white shadow-primary;
    @apply active:focus:bg-[#DD6045] disabled:cursor-not-allowed disabled:opacity-30;
    foo: bar;
    &:hover,
    &:focus,
    &:active {
      @apply shadow-none;
    }
  }
  .two-btn {
    @apply mt-4 flex gap-4 flat:flex-col-reverse;
  }
}
