@layer components {
  input[type="text"] {
    @apply bg-transparent;
  }
  .text-field {
    @apply flex items-center rounded-tl-lg rounded-tr-lg border-b border-solid border-b-black/20 px-2 py-3 font-['Roboto_Slab'];
    foo: bar;

    &:focus-within {
      @apply border-b-blue bg-blue/10 text-blue;
      > span > svg {
        @apply stroke-blue;
      }
    }
  }

  input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
