@layer components {
  #WritingMode {
    #WritingTools {
      @apply flex w-[calc(100%-6rem)] items-center justify-between rounded-3xl bg-white px-3 py-1 shadow-base;
      foo: bar;
      @apply absolute top-3 left-1/2 -translate-x-1/2;

      > ul {
        @apply flex items-center gap-2;

        > li {
          @apply cursor-pointer rounded-[4px] p-[2px] hover:bg-blue/10;
          foo: bar;

          > .svg-fill {
            @apply fill-blue/50;
          }
          > .svg-stroke {
            @apply stroke-blue/50;
          }
        }
        > li.active {
          @apply bg-blue/10;

          > .svg-fill {
            @apply fill-blue;
          }
          > .svg-stroke {
            @apply stroke-blue;
          }
        }
      }
    }

    .react-colorful {
      @apply absolute top-12 left-16 h-28 w-28;
    }
  }
}
